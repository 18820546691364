import { useRef, useEffect, useState } from "react";
import "../Lp-BTK-SRZ3900.css";
import videoBg from "../../../Assets/BTK-SRZ3900-1.mp4";
import sireneFoto from "../../../Assets/BTK_SRZ3900_LATERAL.webp";

const LpBTKSRZ3900A = () => {
  const [muteVideo, setMuteVideo] = useState(true);
  const toggleVideoAudio = () => setMuteVideo((prev) => !prev);

  return (
    <div className="landing-page-3900">
      <div className="lp-hero">
        <span className="lp-hero-title">
          <h1>AUMENTE A SEGURANÇA DE SEU BARRACÃO</h1>

          <spn className="lp-hero-call">
            <h2 className="sku">BTK-SRZ3900</h2>
            <h3>Sinalizador audiovisual para ponte rolante</h3>
          </spn>
        </span>
        <div className="lp-hero-image">
          <img className="img-3900" alt="BTK-SRZ3900" src={sireneFoto} />
        </div>
      </div>
      <div className="lp-content">
        <div className="lp-content-video">
          <h4>CLIQUE NO VÍDEO PARA OUVIR O SOM</h4>
          <video src={videoBg} autoPlay loop muted={muteVideo} />
          <div
            className={muteVideo ? "overlay-video" : "overlay-video unmuted"}
            onClick={() => {
              toggleVideoAudio();
            }}
          >
            <span
              class={
                muteVideo
                  ? "material-symbols-outlined"
                  : "material-symbols-outlined unmuted"
              }
            >
              {muteVideo ? "volume_off" : "volume_up"}
            </span>
          </div>
        </div>
        <div className="action">
          <div className="product-data-lp">
            <h2>MAIS SOBRE A BTK-SRZ3900</h2>
            <ul className="product-specs">
              <li>
                <span class="material-symbols-outlined">volume_up</span>
                Sinalização acústica do tipo piezoelétrica, atingindo 117dB
              </li>
              <li>
                <span class="material-symbols-outlined">flare</span>
                Sinalização visual do tipo flash na cor ambar
              </li>
              <li>
                <span class="material-symbols-outlined">texture</span>
                Estrutura durável em ABS
              </li>
              <li>
                <span class="material-symbols-outlined">stroke_partial</span>
                Resistente a poeira e ferrugem
              </li>
            </ul>
          </div>
          <div className="product-module">
            <h2>(Opcional) Módulo de posição BTK-SZ560</h2>
          </div>
          <div className="lp-buttons-row">
            <a
              href="https://api.whatsapp.com/send?phone=5541992779580&text=Ol%C3%A1%21+estou+no+site+e+gostaria+de+mais+informa%C3%A7%C3%B5es+da+BTK-SRZ3900."
              target="_blank"
            >
              <button className="black-button">Saiba mais </button>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5541992779580&text=Ol%C3%A1%21+estou+no+site+e+gostaria+de+comprar+a+BTK-SRZ3900."
              target="_blank"
            >
              <button className="orange-button">Comprar agora</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LpBTKSRZ3900A;
