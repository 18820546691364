import React from "react";
import "./HeroSection.css";
import HeroAction from "../HeroAction/HeroAction";
import { ReactComponent as TextSVG } from "../../img/svgs/svg_hero.svg";

export default function HeroSection() {
  return (
    <div className="hero">
      <div className="hero-content">
        <HeroAction />
        <div className="hero-img">
          <TextSVG />
        </div>
      </div>
    </div>
  );
}
