import React, { useEffect } from "react";
import "./CookieBar.css";
import useLocalStorage from "react-use-localstorage";

const CookieBar = () => {
  const [localStoragelgpd, setlocalStoragelgpd] = useLocalStorage(
    "lgpd",
    "false"
  );

  useEffect(() => {
    const storageValue = localStorage.getItem("lgpd");
    if (storageValue === "false") {
      setlocalStoragelgpd("false");
    }
  }, []);

  const Entendo = () => {
    setlocalStoragelgpd("true");
  };

  return (
    <>
      {localStoragelgpd === "false" ? (
        <div className="cookies-msg" id="cookies-msg">
          <div className="cookies-txt">
            <p>
              Usamos cookies para garantir que você tenha a melhor experiência
              em nosso site. Se continuar a usar este site, assumiremos que está
              satisfeito com ele e nos autorizaremos a processar seus dados.
            </p>
            <div className="cookies-btn">
              <button onClick={Entendo}>Eu entendo</button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookieBar;
