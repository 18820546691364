import { useEffect } from "react";

import ProductModel from "../ProductModel/ProductModel";

import "./Product.css";

const Product = ({ img, sku, description, ip, db, models }) => {
  let image =
    img === null
      ? `https://btk.solutions/images/products/${sku}/${sku}.webp`
      : img;

  useEffect(() => {
    scrollTo(top);
  });

  return (
    <div className="product">
      <div className="product-img">
        <img alt={sku} src={image} />
        <div className="product-characteristics">
          <span className="product-characteristic">{ip}</span>
          <span className="product-characteristic">{db}</span>
        </div>
      </div>
      <div className="product-data">
        <h2>{sku}</h2>
        <p>{description}</p>
        <div className="product-models">
          <h3>MODELOS DISPONÍVEIS</h3>
          <div className="models">
            {models.map((model, i) => (
              <>
                <ProductModel
                  sku={model.sku}
                  voltage={model.voltage}
                  datasheet={model.datasheet}
                  manual={model.manual}
                  key={model.sku}
                />
                {models.length - 1 !== i && <div className="vl"></div>}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
