import { useEffect } from "react";

import ProductSection from "../ProductSection/ProductSection";
import HeroSection from "../HeroSection/HeroSection";
import HowWeWorkSection from "../HowWeWorkSection/HowWeWorkSection";
import SolveYourProblemSection from "../SolveYourProblemSection/SolveYourProblemSection";

const Home = ({ openModal }) => {
  useEffect(() => {
    scrollTo(top);
  });

  useEffect(() => {
    document.title = "BTK SOLUTIONS - HOME";
  }, []);

  return (
    <>
      <HeroSection />
      <ProductSection />
      <SolveYourProblemSection openModal={openModal} />
      <HowWeWorkSection />
    </>
  );
};

export default Home;
