import { useEffect } from "react";
import Product from "../../Components/Product/Product";
import products from "../../Database/products.json";

import "./Products.css";

const Audiovisuais = () => {
  const audiovisuais = products.audiovisuais;

  useEffect(() => {
    document.title = "BTK SOLUTIONS - AUDIOVISUAL";
  }, []);

  return (
    <div className="products">
      <h1>SINALIZADORES AUDIOVISUAIS</h1>
      <div className="products-list">
        {audiovisuais.map((audiovisual) => (
          <Product
            key={audiovisual.sku}
            img={audiovisual.img}
            sku={audiovisual.sku}
            description={audiovisual.description}
            ip={audiovisual.ip}
            db={audiovisual.db}
            models={audiovisual.models}
          />
        ))}
      </div>
    </div>
  );
};

export default Audiovisuais;
