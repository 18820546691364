import React from "react";

import pdfIcon from "../../img/icons/pdf-file.svg";

import "./ProductModel.css";

const ProductModel = ({ sku, voltage, datasheet, manual }) => {
  datasheet =
    datasheet === null
      ? `https://btk.solutions/datasheets/${sku}.pdf`
      : datasheet;

  manual =
    manual === null ? `https://btk.solutions/manuals/${sku}.pdf` : manual;

  return (
    <div className="product-model">
      <h4>{sku}</h4>
      <h5>{voltage}</h5>
      <div className="files">
        <a className="file" target="_blank" href={datasheet}>
          <img src={pdfIcon}></img>
          <span>DATASHEET</span>
        </a>
        <a className="file" target="_blank" href={manual}>
          <img src={pdfIcon}></img>
          <span>MANUAL</span>
        </a>
      </div>
    </div>
  );
};

export default ProductModel;
