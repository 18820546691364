import React from "react";
import "./SolveYourProblemSection.css";

import arrowright from "../../img/icons/right-arrow-icon.svg";
import cubeIcon from "../../img/icons/abstract.svg";

const SolveYourProblemSection = ({ openModal }) => {
  return (
    <div className="solve-your-problem-section">
      <div className="solve-action">
        <h1>Solucione seu problema agora</h1>
        <div className="buttons">
          <button className="orange-button" onClick={openModal}>
            Entre em contato
          </button>
        </div>
      </div>
      <div className="solve-img">
        <img alt="Solucionando o amanhã" src={cubeIcon} />
      </div>
    </div>
  );
};

export default SolveYourProblemSection;
