import { useNavigate } from "react-router-dom";

import logo from "../../img/logos/logo-btk-white.svg";
import arrowright from "../../img/icons/right-arrow-icon.svg";
import caretDown from "../../img/icons/caret-down-icon.svg";

import "./Header.css";

export default function Header({ openMenu, openModal }) {
  let navigate = useNavigate();
  return (
    <header className="header">
      <div className="navbar">
        <div className="LogoDiv">
          <img
            src={logo}
            className="Logo"
            alt="BTK SOLUTIONS"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <ul className="navbar-links">
          <li>
            <div className="dropdown">
              <button className="dropdown-title">
                Produtos
                <img alt="Seta para baixo" src={caretDown}></img>
              </button>
              <div className="dropdown-content">
                <a href="acionadores">Acionadores</a>
                <a href="centrais">Centrais</a>
                <a href="sinalizadores-audiovisuais">
                  Sinalizadores Audiovisuais
                </a>
                <a href="sinalizadores-visuais">Sinalizadores Visuais</a>
                <a href="sirenes">Sirenes</a>
              </div>
            </div>
          </li>
          <li>
            <a href="sobre-nos">Sobre nós</a>
          </li>
          <li>
            <a href="blog">Blog</a>
          </li>
        </ul>
      </div>
      <div className="navbar-buttons">
        <button className="orange-button" onClick={openModal}>
          Entre em contato
        </button>
      </div>
      <span className="hamburguer" id="hamburguer-btn" onClick={openMenu}>
        <span className="line1"></span>
        <span className="line2"></span>
        <span className="line3"></span>
      </span>
    </header>
  );
}
