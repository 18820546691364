import React from "react";
import * as emailjs from "emailjs-com";
import apiKey from "../../emailkey.js";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import "./ContactSection.css";

export default function ContactSection() {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email é obrigatório")
      .email("Insira um email válido"),
    message: Yup.string().required(
      "Preencha a proposta para que possamos entender melhor seu problema."
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = (data) => {
    console.log(data);
    emailjs
      .send(`default_service`, apiKey.MINI_TEMPLATE_ID, data, apiKey.USER_ID)
      .then(
        (result) => {
          alert("Mensagem enviada, responderemos em breve", result.text);
          reset();
        },
        (error) => {
          alert("An error occurred, Please try again", error.text);
        }
      );
  };
  return (
    <div className="contact-section">
      <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-title">
          <h2>Fale conosco</h2>
          <span>Envie sua proposta, sua solução está à alguns cliques</span>
        </div>
        <div className="contact-email">
          <span className="label">Email</span>
          <input
            {...register("email")}
            type="text"
            className={`text-email ${errors.email ? "is-invalid" : ""}`}
            placeholder="email@email.com"
            name="email"
            id="email"
            autoComplete="email"
          />
          <p className="invalid-feedback">{errors.email?.message}</p>
        </div>
        <div className="contact-proposta">
          <span className="label">Proposta</span>
          <textarea
            {...register("message")}
            className={`text-proposta ${errors.message ? "is-invalid" : ""}`}
            placeholder="Escreva sua proposta, ideia ou mensagem"
            name="message"
            id="message"
          />
          <p className="invalid-feedback">{errors.message?.message}</p>
        </div>
        <div className="contact-buttons">
          <button className="orange-button">Entre em contato</button>
        </div>
      </form>
    </div>
  );
}
