import React, { useRef, useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import * as emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import bolhas from "../../img/backgrounds/fundo-abstrato.svg";
import local from "../../img/icons/local-icon.svg";
import mail from "../../img/icons/email-icon.svg";
import phone from "../../img/icons/telefone-icon.svg";
import instagram from "../../img/icons/instagram-icon-white.svg";
import facebook from "../../img/icons/facebook-icon-white.svg";
import linkedin from "../../img/icons/linkedin-icon-white.svg";

import apiKey from "../../emailkey.js";

import "./ModalContact.css";

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  color: var(--laranja);
`;

export const ModalContact = ({ showModal, setShowModal }) => {
  const [buttonText, setButtonText] = useState("Enviar proposta");
  const [percentage, setPercentage] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    company: Yup.string().required("Empresa é obrigatória"),
    email: Yup.string()
      .required("Email é obrigatório")
      .email("Insira um email válido"),
    message: Yup.string().required(
      "Preencha a proposta para que possamos entender melhor seu problema."
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const onSubmit = (data) => {
    setPercentage(30);
    setButtonText("Enviando...");
    setTimeout(() => {
      setPercentage(90);
    }, "1000");
    emailjs
      .send(`default_service`, apiKey.TEMPLATE_ID, data, apiKey.USER_ID)
      .then(
        () => {
          setPercentage(200);
          setButtonText("Enviado!");
          setTimeout(() => {
            setShowSuccess(true);
            reset();
            setButtonText("Enviar proposta");
            setPercentage(0);
          }, "1000");
        },
        (error) => {
          setButtonText("Enviar proposta");
          setPercentage(0);
          console.log(error);
        }
      );
  };

  return (
    <>
      {showModal ? (
        <div className="background" onClick={closeModal} ref={modalRef}>
          <div className="modal-wrapper" showModal={showModal}>
            {!showSuccess ? (
              <div className="modal-form">
                <img className="modal-img" src={bolhas} alt="Formas" />
                <div className="contact-info">
                  <div className="contact-header">
                    <h1>Fale conosco</h1>
                    <p>
                      Envie sua proposta, sua solução está próxima. Respondemos
                      em menos de uma hora!
                    </p>
                  </div>
                  <div className="contact-infos">
                    <div className="info-plus-icon">
                      <img alt="Telefone" src={phone} />
                      <a href="tel:554133857134">(41) 3385-7134</a>
                    </div>
                    <div className="info-plus-icon">
                      <img alt="Email" src={mail} />
                      <a href="mailto:contato@btk.solutions">
                        contato@btk.solutions
                      </a>
                    </div>
                    <div className="info-plus-icon">
                      <img alt="Local" src={local} />
                      <a href="https://www.google.com/maps/place/R.+Dolovico+Pissaia,+1385+-+Jardim+Independencia,+S%C3%A3o+Jos%C3%A9+dos+Pinhais+-+PR,+83050-080/data=!4m2!3m1!1s0x94dcf061d13deafd:0xc6e49cbe27ceb1d6?sa=X&ved=2ahUKEwiQ28_1iZD3AhVAnpUCHXMcD3oQ8gF6BAgIEAE">
                        São José dos Pinhais - PR
                      </a>
                    </div>
                  </div>
                  <div className="modal-social-media">
                    <ul className="modal-social-media-logos">
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/btksolutions"
                        >
                          <img alt="Instagram" src={instagram} />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.facebook.com/btksolutlons"
                        >
                          <img alt="Facebook" src={facebook} />
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          href="https://www.linkedin.com/company/btk-solutions"
                        >
                          <img alt="Linkedin" src={linkedin} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="modal-content">
                  <form
                    className="modal-contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="modal-row">
                      <div className="modal-contact-text">
                        <span className="modal-label">Nome *</span>
                        <input
                          {...register("name")}
                          type="text"
                          className={`modal-text ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          name="name"
                          id="name"
                        />
                        <p className="invalid-feedback">
                          {errors.name?.message}
                        </p>
                      </div>
                      <div className="modal-contact-text">
                        <span className="modal-label">Empresa *</span>
                        <input
                          {...register("company")}
                          type="text"
                          className={`modal-text ${
                            errors.company ? "is-invalid" : ""
                          }`}
                          name="company"
                          id="company"
                        />
                        <p className="invalid-feedback">
                          {errors.company?.message}
                        </p>
                      </div>
                    </div>
                    <div className="modal-row">
                      <div className="modal-contact-text">
                        <span className="modal-label">Email *</span>
                        <input
                          {...register("email")}
                          name="email"
                          id="email"
                          type="text"
                          className={`modal-text ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          placeholder="email@email.com.br"
                        />
                        <p className="invalid-feedback">
                          {errors.email?.message}
                        </p>
                      </div>
                      <div className="modal-contact-text">
                        <span className="modal-label">Telefone</span>
                        <input
                          {...register("phone")}
                          name="phone"
                          id="phone"
                          type="text"
                          className="modal-text"
                          placeholder="(00) 0000-0000"
                        />
                      </div>
                    </div>
                    <div className="modal-contact-text">
                      <span className="modal-label">Proposta *</span>
                      <textarea
                        {...register("message")}
                        className={`modal-text-proposta ${
                          errors.message ? "is-invalid" : ""
                        }`}
                        placeholder="Escreva sua proposta, ideia ou mensagem"
                        name="message"
                        id="message"
                      />
                      <p className="invalid-feedback">
                        {errors.message?.message}
                      </p>
                    </div>
                    <div className="modal-contact-buttons">
                      {errors.email ||
                      errors.name ||
                      errors.message ||
                      errors.company ? (
                        ""
                      ) : (
                        <button className="orange-button orange-button-width">
                          <div
                            className="orange-button-progress"
                            style={{ width: percentage }}
                          ></div>
                          <span className="button-text">{buttonText}</span>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ) : (
              <div className="modal-success">
                <div className="success-text">
                  <h1>OBRIGADO!</h1>
                  <h2>Sua solução está cada vez mais próxima.</h2>
                  <h3>Dentro de no máximo 24 horas retornaremos o contato.</h3>
                </div>
              </div>
            )}
            <CloseModalButton
              aria-label=""
              onClick={() => setShowModal((prev) => !prev)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
