import React from "react";
import "./ProductSection.css";
import CategoryCard from "../CategoryCard/CategoryCard";
import acionadorIcon from "../../img/categories/acionador.svg";
import sinalizadoVisualIcon from "../../img/categories/sinalizador_visual.svg";
import sinalizadoAudioVisualIcon from "../../img/categories/sinalizador_audiovisual.svg";
import sireneIcon from "../../img/categories/sirene.svg";
import centralIcon from "../../img/categories/central.svg";

import { Carousel } from "3d-react-carousal";

let slides = [
  <CategoryCard
    cardImg={acionadorIcon}
    cardTitle="Acionadores"
    link="/acionadores"
  />,
  <CategoryCard
    cardImg={sinalizadoVisualIcon}
    cardTitle="Sinalizadores Visuais"
    link="/sinalizadores-visuais"
  />,
  <CategoryCard
    cardImg={sinalizadoAudioVisualIcon}
    cardTitle="Sinalizadores Audiovisuais"
    link="/sinalizadores-audiovisuais"
  />,
  <CategoryCard cardImg={sireneIcon} cardTitle="Sirenes" link="/sirenes" />,
  <CategoryCard cardImg={centralIcon} cardTitle="Centrais" link="/centrais" />,
];

const callback = (index) => {
  console.log("callback", index);
};

export default function ProductSection() {
  return (
    <div className="product-section">
      <div className="product-section-content">
        <h1>Produtos gerados a partir de inovação</h1>
        <div className="products-categories">
          <CategoryCard
            cardImg={acionadorIcon}
            cardTitle="Acionadores"
            link="/acionadores"
          />
          <CategoryCard
            cardImg={sinalizadoVisualIcon}
            cardTitle="Sinalizadores Visuais"
            link="/sinalizadores-visuais"
          />
          <CategoryCard
            cardImg={sinalizadoAudioVisualIcon}
            cardTitle="Sinalizadores Audiovisuais"
            link="/sinalizadores-audiovisuais"
          />
          <CategoryCard
            cardImg={sireneIcon}
            cardTitle="Sirenes"
            link="/sirenes"
          />

          <CategoryCard
            cardImg={centralIcon}
            cardTitle="Centrais"
            link="/centrais"
          />
        </div>
        <Carousel
          slides={slides}
          autoplay={false}
          interval={1000}
          onSlideChange={callback}
        />
      </div>
    </div>
  );
}
