import React from "react";
import "./HowWeWorkSection.css";

import Point from "../Point/Point";

import graphIcon from "../../img/icons/graph-icon.svg";
import timerIcon from "../../img/icons/timer-icon.svg";
import wrenchIcon from "../../img/icons/wrench-icon.svg";
import arrowright from "../../img/icons/black-right-arrow-icon.svg";

import logoKlabin from "../../img/logos/klabin.svg";
import logoBrf from "../../img/logos/brf.svg";
import logoIcn from "../../img/logos/icn.svg";
import logoJsl from "../../img/logos/jsl.svg";
import logoMovida from "../../img/logos/movida.svg";
import logoMrs from "../../img/logos/mrs.svg";
import logoPetrobras from "../../img/logos/petrobras.svg";
import logoUfrj from "../../img/logos/ufrj.svg";
import logoVale from "../../img/logos/vale.svg";

function HowWeWorkSection() {
  return (
    <div className="about-us-section">
      <div className="how-we-work" id="know-more">
        <div className="how-we-work-title">
          <h2>O seu problema nossa solução</h2>
          <p>
            Você tem a ideia, nós desenvolvemos a solução, somos apaixonados por
            novos olhares e possibilidades
          </p>
        </div>
        <div className="how-we-work-content">
          <div className="points">
            <Point
              pointImg={timerIcon}
              pointText={"Entrega personalizadas em 6 semanas"}
            />
            <Point
              pointImg={graphIcon}
              pointText={"Feedback e consultoria agile"}
            />
            <Point
              pointImg={wrenchIcon}
              pointText={"Manutenção e continous delivery"}
            />
          </div>
        </div>
      </div>
      <div className="how-we-work">
        <div className="how-we-work-title">
          <h2>Fazendo o melhor junto às maiores</h2>
          <p>
            Soluções personalizadas de qualidade e inovação em suas entregas
            avaliadas positivamente em mais de 500 projetos.
          </p>
        </div>
        <div className="clients">
          <img alt="Klabin" src={logoKlabin} className="client" />
          <img alt="ICN" src={logoIcn} className="client" />
          <img alt="Vale" src={logoVale} className="client" />
          <img alt="BRF" src={logoBrf} className="client" />
          <img alt="PETROBRAS" src={logoPetrobras} className="client" />
          <img alt="MRS" src={logoMrs} className="client" />
          <img alt="JSL" src={logoJsl} className="client" />
          <img alt="Movida" src={logoMovida} className="client" />
          <img alt="UFRJ" src={logoUfrj} className="client" />
        </div>
      </div>
      <div className="how-we-work-saiba-mais-section">
        <div className="how-we-work-saiba-mais">
          <a href="sobre-nos">Saiba mais sobre nós</a>
          <img alt="Seta para direita" src={arrowright}></img>
        </div>
      </div>
    </div>
  );
}

export default HowWeWorkSection;
