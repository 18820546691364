import React from "react";
import "./Point.css";

export default function Point({ pointImg, pointText }) {
  return (
    <div className="Point">
      <div className="icon">
        <img alt={pointText} src={pointImg} />
        <div className="line"></div>
      </div>
      <p>{pointText}</p>
    </div>
  );
}
