import { useEffect } from "react";
import Product from "../../Components/Product/Product";
import products from "../../Database/products.json";

import "./Products.css";

const Sirenes = () => {
  const sirens = products.sirens;

  useEffect(() => {
    document.title = "BTK SOLUTIONS - SIRENES";
  }, []);

  return (
    <div className="products">
      <h1>SIRENES</h1>
      <div className="products-list">
        {sirens.map((siren) => (
          <Product
            key={siren.sku}
            img={siren.img}
            sku={siren.sku}
            description={siren.description}
            ip={siren.ip}
            db={siren.db}
            models={siren.models}
          />
        ))}
      </div>
    </div>
  );
};

export default Sirenes;
