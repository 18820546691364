import React from "react";
import "./Privacy.css";

export default function Privacy() {
  return (
    <div className="privacy">
      <div className="privacy-introduction">
        <h1>POLÍTICA DE PRIVACIDADE</h1>
        <p>
          A privacidade do Usuário é muito importante para a BTK SOLUTIONS
          (“BTK”). Dessa forma, desenvolvemos uma Política de Privacidade que
          dispõe sobre a maneira como coletamos, usamos, divulgamos
          (compartilhamos), transferimos e armazenamos as informações do
          Usuário.
        </p>
        <p>
          Por favor, verifique atentamente o teor desta Política e entre em
          contato por meio do canal FALE CONOSCO caso tenha quaisquer dúvidas.
        </p>
        <p>
          O Usuário concorda, de forma livre, expressa e informada, que seus
          dados e informações sejam coletados, armazenados, tratados e
          compartilhados, conforme as regras dispostas nesta Política de
          Privacidade.
        </p>
      </div>
      <ul className="privacy-topics">
        <li>
          <h2>1. INFORMAÇÕES COLETADAS</h2>
          <p>
            A BTK coletará os Dados Pessoais inseridos ou encaminhados
            ativamente pelo Usuário ao acessar o site e solicitar um contato
            comercial ou contratar diretamente produtos e serviços BTK, por meio
            da web site da BTK. As informações coletadas no primeiro acesso
            serão Nome, e-mail, telefone, nome da empresa e CNPJ. A BTK também
            poderá receber Dados Pessoais quando os Usuários incluírem
            comentários no site, através do FALE CONOSCO.
          </p>
          <p>
            Algumas informações, inclusive pessoais, podem ainda ser coletadas
            automaticamente pela BTK, como endereço IP, tipo de navegador de
            acesso, número de identificação do dispositivo de acesso utilizado
            (Código ID ou IMEI, por exemplo), data e hora do acesso à plataforma
            BTK, inclusive de visitantes.
          </p>
          <p>
            Informações não pessoais podem também ser coletadas pela BTK, como
            dados clusterizados. Informações sobre as atividades do Usuário na
            plataforma BTK, uma vez agregadas, deixam de ser dados pessoais,
            pelo fato de não possibilitar a identificação do titular de dados
            direta ou indiretamente. Estes dados podem ser utilizados para
            ajudar a BTK a fornecer um melhor serviço para os Usuários em geral.
          </p>
          <p>
            Caso a BTK combine informações não pessoais com informações
            pessoais, a informação combinada será tratada como informação
            pessoal enquanto permanecer combinada.
          </p>
        </li>
        <li>
          <h2>2. UTILIZAÇÃO DAS INFORMAÇÕES COLETADAS</h2>
          <p>
            As Informações são tratadas pela BTK com as seguintes finalidades:
          </p>
          <p>
            Execução de serviços, produtos ou contratos, como: orçamento,
            negociação, contrato, atendimento ao cliente, entre outros.
          </p>
          <p>
            Possibilitar o acesso e o uso dos recursos e funcionalidades do site
            e/ou aplicativo da BTK pelos Usuários.
          </p>
          <p>
            Cumprir determinações legais ou para o Exercício Regular de
            Direitos, como o armazenamento de informações para defesa judicial
            ou resposta de ofícios;
          </p>
          <p>
            Para atingir os Interesses Legítimos da BTK, sempre no limite da
            expectativa do usuário e sem ferir seus direitos fundamentais, como
            análise de desempenho do site, medir audiência, análises de
            segurança, melhorar experiência do usuário, identificar perfis, e
            hábitos necessários à estratégia da BTK. Para fins de Comunicação,
            através de envio de avisos e notificações importantes, como
            comunicados sobre Pedido, alterações de prazos, condições e
            políticas. Para fins de Marketing indireto, será coletado o
            Consentimento do titular Para fins de marketing direto, quando as
            informações coletadas poderão ser utilizadas para envio de
            publicidade e materiais promocionais, incluindo campanhas digitais.
            Ao Usuário é facultado, a qualquer momento, optar por não receber
            mais e-mails de marketing por meio do link de descadastramento
            fornecido no próprio e-mail.
          </p>
        </li>
        <li>
          <h2>3. COOKIES</h2>
          <p>
            Cookies são arquivos ou informações que podem ser armazenadas no
            dispositivo do Usuário no momento de acesso ao site da BTK.
          </p>
          <p>
            Os cookies permitem que os Usuários sejam reconhecidos quando estes
            retornam ao site. Além disso, possibilita uma experiência
            personalizada podendo ajudar na detecção de determinados tipos de
            fraude.
          </p>
          <p>
            O Usuário poderá gerenciar as preferências de cookies, a BTK
            solicitará autorização ao Usuário, assim que do primeiro acesso do
            site, permitindo a escolha de forma fragmentada quais cookies
            autoriza para tratamento pela BTK.
          </p>
          <span>
            <h3>Necessários</h3>
            <p>
              Os cookies são essenciais para que o website carregue
              adequadamente e permita a navegação correta e de todas as
              funcionalidades.
            </p>
          </span>
          <span>
            <h3>Desempenho</h3>
            <p>
              Os cookies nos ajudam a entender como os visitantes interagem com
              a página da BTK, fornecendo informações sobre as áreas visitadas,
              o tempo de visita ao site e quaisquer problemas encontrados.
            </p>
          </span>
          <span>
            <h3>Funcionais</h3>
            <p>
              Os cookies permitem que a página da BTK registre as escolhas do
              usuário, para proporcionar uma experiência personalizada.
            </p>
          </span>
          <span>
            <h3>Marketing</h3>
            <p>
              Os cookies são utilizados para fornecer mais conteúdo relevante e
              do interesse dos Usuários. Podem ser utilizados para apresentar
              publicidade com um maior direcionamento.
            </p>
          </span>
          <p>
            Nesse sentido ressaltamos que a aceitação dos cookies classificados
            como necessários são essenciais para o normal funcionamento deste
            site, sendo que a oposição a utilização desta ferramenta poderá
            levar a direta e imediata inutilização dos serviços digitais
            disponíveis. É possível a oposição do usuário, quanto aos demais
            cookies informados neste Aviso.
          </p>
        </li>
        <li>
          <h2>4. COMPARTILHAMENTO DAS INFORMAÇÕES</h2>
          <p>
            Para a operacionalização de produtos e serviços oferecidos pela BTK,
            será necessário o compartilhamento de Dados Pessoais. Abaixo estão
            algumas hipóteses, em que o compartilhamento poderá ocorrer:
          </p>
          <p>
            A BTK poderá compartilhar informações do Usuário com empresas ou
            indivíduos prestadores de serviço ou empresas parceiras. Os serviços
            das aludidas empresas poderão ser utilizados para facilitar, prover
            ou executar atividades relacionadas ao site da BTK, incluindo - mas
            não se limitando à -, manutenção dos serviços, gerenciamento de
            banco de dados, análise de dados e melhoria de funcionalidades
            incluindo marketing ou auxílio na avaliação de como a plataforma da
            BTK é usada.
          </p>
          <p>
            Caso o Usuário solicite a exclusão de seu cadastro, as informações
            pessoais fornecidas ao site BTK serão excluídas definitivamente
            sempre que a legislação assim permitir, e não houver motivos legais
            para mantermos estas informações. Em alguns casos, a informação
            poderá ser retida como, por exemplo, para fins de cumprimento de
            obrigação legal, utilização na solução de litígios e/ou outras
            razões permitidas ou advindas de lei.
          </p>
          <p>
            A BTK toma precauções, entre elas medidas administrativas, técnicas
            e físicas, para proteger as informações pessoais do Usuário contra
            perda, roubo, uso indevido, bem como contra acesso não autorizado,
            divulgação, alteração e destruição
          </p>
        </li>
        <li>
          <h2>5. OS DIREITOS DOS TITULARES E COMO EXERCÊ-LOS</h2>
          <p>
            Em cumprimento da regulamentação aplicável, no que diz respeito ao
            tratamento de dados pessoais, a BTK respeita e garante ao Usuário, a
            possibilidade de apresentar requisições baseadas nos seguintes
            direitos:
          </p>
          <ul className="topics-list-dots">
            <li>Confirmação da existência do tratamento;</li>
            <li>
              Anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade com a lei;
            </li>
            <li>
              Portabilidade dos dados a outro fornecedor de serviço ou produto;
            </li>
            <li>
              Eliminação dos dados tratados com consentimento do cliente e/ou
              usuário;
            </li>
            <li>Acesso aos dados;</li>
            <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
            <li>
              Obtenção de informações sobre as entidades com as quais a BTK
              compartilha seus Dados Pessoais;
            </li>
            <li>
              Informação sobre a possibilidade de não fornecer o consentimento e
              as consequências em caso de negativa.
            </li>
            <li>Revogação do consentimento;</li>
          </ul>
          <p>
            Todas as requisições poderão ser solicitadas através do canal FALE
            CONOSCO de forma gratuita, podendo a BTK adotar medidas adequadas
            para garantir a identidade do requisitante.
          </p>
        </li>
        <li>
          <h2>6. INCAPACIDADE LEGAL</h2>
          <p>
            O site da BTK não é direcionado a crianças, adolescentes e/ou
            qualquer pessoa sem capacidade legal para contratar os serviços
            oferecidos. Logo, dados pessoais de crianças e adolescentes não são
            intencionalmente tratados pela BTK. Quaisquer dados desta natureza,
            quando identificados, serão prontamente eliminados.
          </p>
        </li>
        <li>
          <h2>7. DISPOSIÇÕES FINAIS</h2>
          <p>
            As condições especificadas nesta Política de Privacidade poderão ser
            modificadas a qualquer momento. Caso as modificações sejam
            significativas ou afetem, de forma concreta, os direitos e deveres
            aqui dispostos, estas serão apresentadas ao Usuário, de forma clara,
            por meio do e-mail cadastrado.
          </p>
          <p>
            A BTK SOLUTIONS - DISTRIBUIDORA DE EQUIPAMENTOS E INTEGRACAO DE
            SISTEMAS ELETRONICOS LTDA, inscrita no CNPJ sob nº
            32.421.603/0001-53, com sede na R Professora Marieta de Souza e
            Silva, 932 - Bloco 303, CEP 83.050-160, Parque da Fonte, São José
            dos Pinhais, PR, quando Controladora dos dados pessoais, será
            responsável por zelar pela segurança, respeito à finalidade e
            transparência com os dados de seus Clientes e Usuários.
          </p>
          <p>
            Em caso dúvidas relativas à privacidade ou proteção de dados, favor
            contatar o nosso Encarregado pelo Tratamento de Dados Pessoais, por
            meio do endereço eletrônico lgpd@btk.solutions.
          </p>
        </li>
      </ul>
    </div>
  );
}
