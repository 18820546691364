import React from "react";

import "./About-us.css";

import handShake from "../../img/about-us/hand-shake.webp";
import fiveStars from "../../img/about-us/5-stars.webp";
import expertiseProduct from "../../img/about-us/expertise-product.webp";
import expertiseCreator from "../../img/about-us/expertise-creator.webp";
import fistBump from "../../img/about-us/fist-bump.webp";
import missionGdr from "../../img/about-us/mission-gdr.webp";
import quality from "../../img/about-us/quality.webp";
import team from "../../img/about-us/team.webp";
import btkWrench from "../../img/icons/btk-wrench.svg";

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-us-title">
        <div className="about-us-left-column">
          <span>
            <div className="low-rectangle" />
            <h1>
              SOBRE
              <span className="nos">NÓS</span>
            </h1>
          </span>
        </div>
        <div className="about-us-right-column">
          <div className="med-rectangle" />
        </div>
      </div>
      <section className="history">
        <h2>Nossa História</h2>
        <span>
          <p>
            Desde sua fundação, a BTK SOLUTIONS tem se destacado como uma
            referência em sinalização e segurança. Nossa história começou há
            mais uma década, quando uma dupla de empreendedores apaixonados pela
            indústria decidiu unir sua expertise para fornecer soluções
            inovadoras.
          </p>
          <p>
            Ao longo dos anos, enfrentamos desafios e celebramos conquistas
            significativas. Crescemos de forma consistente, expandindo nosso
            alcance e aprimorando nossos produtos para atender às necessidades
            sempre evolutivas do mercado.
          </p>
          <p>
            Nossa visão pioneira nos levou a desenvolver tecnologias de ponta e
            estabelecer parcerias estratégicas com os principais players do
            setor. Com dedicação e foco, conquistamos a confiança de clientes em
            diversos setores industriais, oferecendo soluções de sinalização
            confiáveis e eficientes.
          </p>
          <p>
            Acreditamos que o sucesso da BTK SOLUTIONS está diretamente ligado à
            paixão e comprometimento de nossa equipe. Nossos colaboradores são
            especialistas em suas áreas e compartilham um objetivo comum:
            garantir a segurança e proteção das pessoas em ambientes
            industriais.
          </p>
          <p>
            Olhando para o futuro, estamos firmemente comprometidos em continuar
            nossa trajetória de excelência, investindo em pesquisa e
            desenvolvimento para oferecer soluções cada vez mais avançadas.
            Nossa prioridade é sempre superar as expectativas de nossos
            clientes, proporcionando-lhes tranquilidade e confiança em seus
            ambientes de trabalho.
          </p>
          <p>
            Estamos orgulhosos de nossa jornada até aqui e entusiasmados com o
            que o futuro reserva. Continuaremos inabaláveis em nossa busca por
            qualidade, inovação e excelência em todas as soluções de sinalização
            que oferecemos.
          </p>
        </span>
        <span className="history-footer">
          <div className="big-rectangle" />

          <div className="history-imgs">
            <img className="hand-shake" src={handShake} />
          </div>
        </span>
      </section>
      <section className="our-mission">
        <div className="about-us-right-column">
          <h2 className="mission-title">Missão</h2>
          <div className="med-rectangle" />
        </div>
        <span>
          <p>
            Na BTK SOLUTIONS, nossa missão é fornecer soluções de sinalização de
            alta qualidade e confiabilidade, contribuindo para a segurança e
            proteção de ambientes industriais em todo o mundo. Nosso compromisso
            é oferecer produtos que atendam aos mais rigorosos padrões de
            qualidade e conformidade, garantindo a tranquilidade de nossos
            clientes.
          </p>
          <p>
            Acreditamos que a segurança é uma prioridade absoluta em qualquer
            ambiente de trabalho. Por isso, trabalhamos incessantemente para
            desenvolver e aprimorar nossas soluções, utilizando tecnologias
            avançadas e processos de fabricação de última geração.
          </p>
          <p>
            Além de fornecer produtos de excelência, também buscamos estabelecer
            relacionamentos duradouros com nossos clientes. Valorizamos a
            parceria e a confiança mútua, pois sabemos que a colaboração é
            essencial para alcançar resultados excepcionais.
          </p>
          <p>
            Nossa equipe altamente capacitada e dedicada está sempre pronta para
            ajudar e oferecer suporte técnico especializado. Estamos
            comprometidos em entender as necessidades específicas de cada
            cliente e fornecer soluções personalizadas que atendam aos seus
            requisitos.
          </p>
          <p>
            Na BTK SOLUTIONS, sabemos que a sinalização não é apenas um
            componente físico, mas sim uma ferramenta vital para a segurança e
            comunicação eficaz. Estamos empenhados em fornecer soluções que
            sejam visualmente impactantes, intuitivas e fáceis de serem
            compreendidas em qualquer situação.
          </p>
          <p>
            Nossa missão é clara: proteger vidas, preservar ambientes de
            trabalho seguros e fornecer soluções de sinalização que inspirem
            confiança e tranquilidade. Estamos comprometidos em ser o parceiro
            confiável e preferido de nossos clientes quando se trata de
            sinalização industrial.
          </p>
          <p>
            Junte-se a nós nessa jornada de segurança e sucesso. Estamos prontos
            para fazer a diferença em seu ambiente de trabalho.
          </p>
        </span>
        <span className="mission-footer">
          <div className="big-rectangle" />

          <div className="mission-imgs">
            <img className="gdr-mission" src={missionGdr} />
            <img className="fist-bump" src={fistBump} />
          </div>
        </span>
      </section>
      <section className="expertise">
        <div className="expertise-left-column">
          <h2>Expertise</h2>
          <div className="med-rectangle" />
        </div>
        <span className="expertise-content">
          <span className="expertise-text">
            <p>
              Com uma experiência sólida e bem estabelecida no setor de
              sinalização industrial, a BTK SOLUTIONS é reconhecida como uma
              líder de mercado. Ao longo dos anos, temos orgulho de ter
              fornecido soluções confiáveis e inovadoras para empresas de
              diversos segmentos e tamanhos.
            </p>
            <p>
              Nossa equipe é composta por profissionais altamente qualificados e
              especializados no desenvolvimento e fabricação de produtos de
              sinalização. Com um profundo conhecimento das necessidades e
              exigências do mercado, estamos sempre atualizados com as
              tendências e avanços tecnológicos mais recentes.
            </p>
            <p>
              Ao longo de nossa trajetória, conquistamos a confiança de nossos
              clientes por meio da entrega consistente de produtos de alta
              qualidade e do compromisso em superar suas expectativas. Nosso
              portfólio diversificado abrange uma ampla gama de soluções de
              sinalização, desde sirenes e alarmes visuais até placas de
              advertência e sistemas de evacuação.
            </p>
            <p>
              Investimos continuamente em pesquisa e desenvolvimento para
              aprimorar nossos produtos e introduzir inovações que atendam às
              demandas em constante evolução do mercado. Além disso, mantemos
              parcerias estratégicas com fornecedores confiáveis e respeitados,
              garantindo acesso a materiais e tecnologias de ponta.
            </p>
            <p>
              A BTK SOLUTIONS também se destaca pelo seu compromisso com a
              excelência no atendimento ao cliente. Valorizamos cada interação
              com nossos clientes e buscamos entender suas necessidades
              específicas, oferecendo suporte técnico especializado, orientações
              precisas e soluções personalizadas.
            </p>
            <p>
              Nossa experiência é o alicerce sobre o qual construímos nossa
              reputação de qualidade, confiabilidade e comprometimento. Estamos
              confiantes de que podemos oferecer a você as melhores soluções de
              sinalização industrial, apoiadas por anos de expertise e um
              histórico comprovado de sucesso.
            </p>
          </span>
          <span className="expertise-column">
            <img className="expertise-product" src={expertiseProduct} />
            <img className="expertise-creator" src={expertiseCreator} />
          </span>
        </span>
      </section>
      <section className="our-client">
        <div className="our-client-left-column">
          <div className="med-rectangle" />
          <h2>Cliente</h2>
        </div>
        <span className="our-client-content">
          <span className="our-client-text">
            <p>
              Na BTK SOLUTIONS, temos um compromisso inabalável em oferecer a
              melhor experiência para nossos clientes. Nossa abordagem ao
              cliente é pautada pela transparência, comunicação clara e
              eficiente, e atendimento personalizado.
            </p>
            <p>
              Valorizamos cada interação com nossos clientes e buscamos entender
              suas necessidades e desafios específicos. Acreditamos que cada
              projeto é único e merece atenção especial. Por isso, nossa equipe
              dedicada está pronta para oferecer suporte técnico especializado,
              responder a dúvidas e fornecer orientações para ajudar nossos
              clientes a tomar as melhores decisões em relação à sinalização
              industrial.
            </p>
            <p>
              Além disso, estamos sempre abertos ao feedback dos nossos
              clientes. Valorizamos suas opiniões e procuramos constantemente
              melhorar nossos produtos e serviços com base em suas sugestões.
            </p>
            <p>
              Nossa meta é estabelecer parcerias duradouras com nossos clientes,
              fornecendo soluções confiáveis, de alta qualidade e atendimento
              excepcional. Estamos prontos para ajudá-lo a alcançar os melhores
              resultados em seus projetos de sinalização industrial.
            </p>
          </span>
          <span className="our-client-column">
            <div className="big-rectangle" />
            <img className="our-client-five" src={fiveStars} />
          </span>
        </span>
      </section>
      <section className="team">
        <div className="team-title">
          <div className="med-rectangle" />
          <h2>Equipe</h2>
          <div className="med-rectangle" />
        </div>
        <span className="team-imgs">
          <img className="team-img" src={team} />
          <img className="btk-wrench" src={btkWrench} />
        </span>
        <span className="team-subtitle">
          <h2>Somos Creators!</h2>
          <h3>BELIEVE, TAKE ACTION AND KEEP MOVING</h3>
        </span>
        <span className="team-text">
          <p>
            Na BTK SOLUTIONS, contamos com uma equipe altamente qualificada e
            dedicada, composta por profissionais experientes e apaixonados pelo
            que fazem. Nossa equipe é formada por engenheiros, designers,
            especialistas em marketing e vendas, todos trabalhando em sinergia
            para entregar os melhores produtos e serviços aos nossos clientes.
          </p>
          <p>
            Valorizamos o trabalho em equipe, incentivando a colaboração, o
            compartilhamento de ideias e a busca constante pela excelência.
            Nossos colaboradores são constantemente treinados e atualizados nas
            últimas tendências e tecnologias do mercado, garantindo que estejam
            preparados para enfrentar os desafios e oferecer soluções
            inovadoras.
          </p>
          <p>
            Acreditamos que a força da nossa equipe é fundamental para o sucesso
            da BTK SOLUTIONS. Estamos comprometidos em promover um ambiente de
            trabalho inclusivo, respeitoso e inspirador, onde cada membro da
            equipe possa crescer profissionalmente e contribuir para o
            crescimento da empresa.
          </p>
          <p>
            Juntos, estamos prontos para enfrentar qualquer desafio e superar as
            expectativas dos nossos clientes. Nossa equipe está à disposição
            para oferecer suporte, orientação e soluções personalizadas, visando
            atender às necessidades específicas de cada cliente e garantir sua
            total satisfação.
          </p>
        </span>
      </section>
      <section className="inovation">
        <div className="inovation-right-column">
          <h2>Qualidade e inovação</h2>
          <div className="med-rectangle" />
        </div>
        <span className="inovation-text">
          <p>
            Na BTK SOLUTIONS, temos um compromisso inabalável com a qualidade e
            a inovação em tudo o que fazemos. Buscamos constantemente a
            excelência em nossos produtos, serviços e processos, para garantir a
            máxima satisfação dos nossos clientes.
          </p>
          <p>
            Nossos produtos são desenvolvidos com materiais de alta qualidade e
            passam por rigorosos processos de controle de qualidade, desde a
            concepção até a entrega final. Trabalhamos em estreita colaboração
            com fornecedores confiáveis e realizamos testes exaustivos para
            garantir que nossos produtos atendam aos mais altos padrões de
            desempenho, durabilidade e segurança.
          </p>
          <p>
            Além disso, estamos sempre buscando inovação e novas soluções para
            atender às necessidades em constante evolução dos nossos clientes.
            Investimos em pesquisa e desenvolvimento, participamos de feiras e
            eventos do setor e mantemos-nos atualizados com as últimas
            tendências e avanços tecnológicos. Dessa forma, podemos oferecer
            produtos e serviços que estejam na vanguarda do mercado, trazendo
            benefícios reais para nossos clientes.
          </p>
          <p>
            Nosso compromisso com a qualidade e a inovação é refletido em cada
            etapa do nosso trabalho, desde o design até a fabricação, o suporte
            pós-venda e o relacionamento com os clientes. Estamos empenhados em
            superar as expectativas e construir relacionamentos de longo prazo
            com nossos clientes, baseados na confiança, na transparência e na
            entrega consistente de valor.
          </p>
          <p>
            Na BTK SOLUTIONS, acreditamos que a qualidade e a inovação são
            fundamentais para o sucesso do nosso negócio e para o sucesso dos
            nossos clientes. Estamos comprometidos em oferecer soluções que
            impulsionem o crescimento e o progresso das empresas, ajudando-as a
            alcançar seus objetivos e se destacar no mercado competitivo.
          </p>
        </span>
      </section>
    </div>
  );
};

export default AboutUs;
