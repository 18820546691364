import React from "react";
import "./Sidebar.css";

import caretDown from "../../img/icons/caret-down-icon.svg";

export const Sidebar = ({ sidebarWidth }) => {
  return (
    <div className={sidebarWidth}>
      <div className="container">
        <div className="sidebar-tittle">
          <h1>Menu</h1>
        </div>
        <ul className="sidebar-links">
          <li>
            <div className="sidebar-dropdown">
              <button className="sidebar-dropdown-title">
                Produtos
                <img alt="Seta para baixo" src={caretDown}></img>
              </button>
              <div className="sidebar-dropdown-content">
                <a href="#">Acionadores</a>
                <a href="#">Centrais</a>
                <a href="#">Sinalizadores Audiovisuais</a>
                <a href="#">Sinalizadores Visuais</a>
                <a href="#">Sirenes</a>
              </div>
            </div>
          </li>
          <li>
            <a href="sobre-nos">Sobre nós</a>
          </li>
          <li>
            <a href="blog">Blog</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
