import React from "react";
import "./Footer.css";

import logo from "../../img/logos/logo-btk-white.svg";
import instagram from "../../img/icons/instagram-icon.svg";
import facebook from "../../img/icons/facebook-icon.svg";
import linkedin from "../../img/icons/linkedin-icon.svg";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="logo-btk-footer">
        <a href="https://btk.solutions">
          <img src={logo} className="Logo" alt="BTK SOLUTIONS" />
        </a>
      </div>
      <div className="footer-content">
        <div className="company-data">
          <div className="company-info">
            <h2>BTK SOLUTIONS</h2>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/R.+Dolovico+Pissaia,+1385+-+Jardim+Independencia,+S%C3%A3o+Jos%C3%A9+dos+Pinhais+-+PR,+83050-080/data=!4m2!3m1!1s0x94dcf061d13deafd:0xc6e49cbe27ceb1d6?sa=X&ved=2ahUKEwiQ28_1iZD3AhVAnpUCHXMcD3oQ8gF6BAgIEAE"
            >
              São José dos Pinhais - PR
            </a>
            <a href="mailto:contato@btk.solutions">contato@btk.solutions</a>
            <a href="tel:554133857134">(41)3385-7134</a>
          </div>
          <div className="social-media">
            <span>Nossas redes:</span>
            <ul className="social-media-logos">
              <li>
                <a
                  target="_blank"
                  href="https://www.instagram.com/btksolutions"
                >
                  <img alt="Instagram" src={instagram} />
                </a>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/btksolutlons">
                  <img alt="Facebook" src={facebook} />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/btk-solutions"
                >
                  <img alt="Linkedin" src={linkedin} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-links">
          <div className="products-links">
            <h2>Produtos</h2>
            <ul>
              <li>
                <a href="/acionadores">Acionadores</a>
              </li>
              <li>
                <a href="/sinalizadores-visuais">Sinalizadores</a>
              </li>
              <li>
                <a href="/sinalizadores-audiovisuais">Sirenes Audiovisuais</a>
              </li>
              <li>
                <a href="/sirenes">Sirenes</a>
              </li>
              <li>
                <a href="/centrais">Centrais</a>
              </li>
            </ul>
          </div>
          <div className="company-links">
            <h2>Empresa</h2>
            <ul>
              <li>
                <a href="sobre-nos">Sobre nós</a>
              </li>
              {/* <li>
                <a href="blog">Blog</a>
              </li> */}
              <li>
                <a href="politica-de-privacidade">Política de privacidade</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
