import "./Feature.css";

const Feature = ({ data, description }) => {
  return (
    <div className="feature">
      <span className="data">{data}</span>
      <p className="description">{description}</p>
    </div>
  );
};

export default Feature;
