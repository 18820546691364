import React from "react";
import { useNavigate } from "react-router-dom";

import "./CategoryCard.css";

export default function CategoryCard({ cardImg, cardTitle, link }) {
  let navigate = useNavigate();
  return (
    <div
      className="category-card"
      onClick={() => {
        navigate(link);
      }}
    >
      <div className="category-card-content">
        <div className="card-img">
          <img alt={cardTitle} src={cardImg} />
        </div>
        <div className="card-text">
          <h3>{cardTitle}</h3>
        </div>
      </div>
      <div className="category-card-saiba-mais">
        <span>Saiba mais</span>
      </div>
    </div>
  );
}
