import "./HeroAction.css";
import Feature from "../Feature/Feature";
import { useState, useEffect } from "react";
import frases from "../../Database/frases.json";

export default function HeroAction() {
  const [fraseIndex, setFraseIndex] = useState(0);
  const [frase, setFrase] = useState("");
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    const characters = frases[fraseIndex].split("");
    let i = 0;
    const intervalId = setInterval(() => {
      setFrase((prevFrase) => prevFrase + characters[i]);
      i++;
      if (i >= characters.length) {
        clearInterval(intervalId);
        setTyping(false);
        setTimeout(() => {
          setFrase("");
          setFraseIndex((prevIndex) => (prevIndex + 1) % frases.length);
        }, 2000);
      }
    }, 100);

    setTyping(true);

    return () => clearInterval(intervalId);
  }, [fraseIndex, frases.length]);

  return (
    <div className="hero-action">
      <div className="hero-message">
        <h1 className="hero-title">DESENVOLVENDO SOLUÇÕES QUE</h1>
        <h1 className="hero-frase">{frase}</h1>
      </div>
      <p className="hero-text">
        Somos a BTK SOLUTIONS, uma empresa especializada em sistemas de alarme e
        sinalização. Nosso objetivo é fornecer soluções de alta qualidade e
        tecnologia avançada para garantir a segurança de pessoas e propriedades.
      </p>
      <div className="features">
        <Feature data={"+500"} description={"Projetos realizados"} />
        <Feature data={"+300"} description={"Empresas atendidas"} />
        <Feature data={"+10"} description={"Anos de experiência"} />
      </div>
    </div>
  );
}
